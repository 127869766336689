import PropTypes from 'prop-types'
import React from 'react'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="">Intro</h2>
          <p>
            I make the impossible possible. <br/>
            <br/>
            This is what I do every day: I solve problems, I dockerize things, I make sure stuff works properly.<br/>
            <br/>
            I handle and work with high traffic / high load web applications, which are usually dockerized, clustered and distributed on multiple systems due to Kubernetes and Rancher.<br/>
            <br/>
            I love technology and problem solving is my lifestyle. I enjoy examining everything I deal with to understand how it works and how to make it work better.<br/>
            <br/>
            Every day, I face new challenges and I always look for the right balance between perfection and deadlines.<br/>
            <br/>
            Fifteen years ago, I started to work with Microsoft technologies (.NET, WPF, UWP).<br/>
            Now, I’m a full stack developer and I daily work with both Frontend and Backend technologies, such as jQuery, AngularJS, React and PHP, nodeJS, Ruby; in addition, I can handle different types of databases, like MySQL, PostgreSQL, Oracle and SqlServer.<br/>
            <br/>
            Lastly, I’m an expert in electronics and IoT (Arduino, ESP*, PIC*) and I have built several devices. <br/>
            <br/>
            {/* I currently divide my time between helping my great IT team to work better and continuing to improve and manage infrastructures. */}
          </p>
          {/* <p>Have a look to my <a href="#work"> work</a>.</p> */}
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="">Work</h2>
          <h3>2020 - Present: Co-founder & CTO @ QODA</h3>
          <p>
            In 2020, at the beginning of the COVID emergency, my team and I created Qoda, a free service to manage queues.<br/>
            Large supermarkets, such as Carrefour, used it to help people queue and avoid gatherings. <br/>
            Some months later, big amusement parks, like Gardaland and Mirabilandia, started to use Qoda for queues in restaurants and attractions.<br/>
            <br/>
            I have developed the backend part of Qoda in nodeJS, and at the moment it manages about 40k RPM with a response time of 20ms.
          </p>
          <h3>2022 - Present: Lead System Architect @ TREATWELL GROUP</h3>
          <p>
            As a natural evolution of Uala, I'm now part of the Treatwell family.<br/>
            We worked hard to make the Uala Platform great, stable, and with 100% uptime.<br/>
            Our trip is not over, we are going to improve that and merge with the Treatwell Platform.<br/>
            I'm continuing my daily job focusing on IaC, Kubernetes, Rancher, and anything related to our Platform.
          </p>
          <h3>2013 - 2022: Co-founder & CTO @ UALA GROUP</h3>
          <p>
            In 2013, together with two business partners, I founded Uala, a great company based in Milan, Italy.<br/>
            Since the beginning, our IT team has always worked fully remotely. <br/>
            As a CTO, I follow the whole development flow from the starting specs to the deploy in production with zero downtime.<br/>
            We have to guarantee 100% uptime: this is why we work every day to improve our platform and systems thanks to new technologies like Rancher and Kubernetes.<br/>
            <br/>
            At this time, we have about 1300 services on our infrastructure and the core backend manages about 20k RPM with less than 70ms of response time.<br/>
          </p>
          <h3>2007 - 2013: Full Stack Developer @ DEDAGROUP </h3>
          <p>
            I developed part of the Civilia Open suite for the public administration of various Italian towns.<br/>
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="">Contact</h2>
          <p>Please, use my social network profiles to contact me. </p>
          <ul className="social-contact">
            <li>
              <a href="https://www.linkedin.com/in/lucamattivi/" target="_blank" rel="noreferrer"
               className="icon fa-linkedin">
                <span className="label">Linkedin</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/Leen15" target="_blank" rel="noreferrer"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/Leen15" target="_blank" rel="noreferrer"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onOpenArticle: PropTypes.func,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
