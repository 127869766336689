import PropTypes from 'prop-types'
import React from 'react'
import profile from '../images/profile3-bw-small.jpg'
import { useLocation } from '@reach/router'

const Header = props => {

  const location = useLocation()

  React.useEffect(
    () => {
      if (location.hash) {
        // detect anchor and open the article
       props.onOpenArticle(location.hash.replace("#", ""));
      }
    },
    [location],
  )

  return (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <img className="profileImg" src={profile} alt="" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Luca Mattivi</h1>
        <p>
          System Architect, Full Stack Developer.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a href="#intro">
            Intro
          </a>
        </li>
        <li>
          <a href="#work">
            Work
          </a>
        </li>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li> */}
        <li>
        <a href="#contact">
            Contact
          </a>
        </li>
      </ul>
    </nav>
  </header>
)}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
